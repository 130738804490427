import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/payouts'

export default {
  namespaced: true,
  state: {
    is_load: false,
    payouts: [],
    payout_system: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setPayouts (state, payload) {
      state.payouts = payload
    },
    setPayout (state, payload) {
      state.payout_system = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getPayouts ({
      state,
      commit
    }, params) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: params
      })

      promise.then(response => {
        commit('setPayouts', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getPayout ({
      state,
      commit
    }, id) {
      return axios.get(baseURL + '/' + id)
    },
    async confirmPayment ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/' + data.id + '/paid', data)
    },
    async cancelPayment ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/' + data.id + '/cancel', data)
    }
  }
}
