import { mapActions, mapMutations, mapState } from 'vuex'
import Echo from 'laravel-echo'

export const ws = {
  data () {
    return {}
  },
  computed: {
    ...mapState('notifications', ['count_unread', 'notifications']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('notifications', ['getUnread', 'getCountUnread', 'getNotifications']),
    ...mapMutations('notifications', ['setNotificationPage']),
    joinWS () {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_SOCKET_HOST,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`
          }
        }
      })

      this.getCountUnread()

      window.Echo.private('App.Models.User.' + this.user.id).notification((notification) => {
        if (notification.type === 'broadcast.notification') {
          this.getCountUnread()
        }
      })

      window.Echo.channel('user.' + this.user.id)
        .listen('.users.new-notification', (e) => {
          this.getCountUnread()
        }).listen('.chats.message_created', (e) => {
          console.log('WS', e)
        //   this.user.telegram_chat = e.telegram_chat
        //    this.showTelegramModel = false
        })
    }
  }
}
