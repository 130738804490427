import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/projects'
const paymentURL = process.env.VUE_APP_SERVER_URL + '/api/v2/payments'

export default {
  namespaced: true,
  state: {
    is_load: false,
    project: null,
    projects: [],
    filters: {
      user_id: null
    },
    pagination: {
      current_page: 1
    }
  },
  mutations: {
    setProject (state, payload) {
      state.project = payload
    },
    setProjects (state, payload) {
      state.projects = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setUserId (state, payload) {
      state.filters.user_id = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getUserProjects ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          user_id: filters.user_id,
          project_search: filters.project_search,
          transaction_search: filters.transaction_search,
          page: state.pagination.current_page || 1
        }
      })
      promise.then(response => {
        commit('setProjects', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getMiniProjectList ({ state, commit }, clientID) {
      return axios.get(baseURL + '/list', {
        params: {
          client_id: clientID
        }
      })
    },
    async getMiniProjectSearchList ({ state, commit }, params) {
      return axios.get(baseURL + '/search', {
        params: params
      })
    },
    async getProject ({ state, commit }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async updateProject ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async updateProjectStatus ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id + '/set-status', data)
      promise.then(response => {
        commit('setProject', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async confirmProject ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id + '/confirm')
      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async createConfirmTariff ({ state, commit }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id + '/create-confirm-tariff', data)
      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async createPayment ({ state, commit }, data) {
      const newAxiosInstance = axios.create()

      newAxiosInstance.defaults.headers.common['Message-Checksum'] = data.headers.check_sum
      return newAxiosInstance.post(paymentURL + '/client-to-server', data.body, {
        auth: {
          username: data.headers.user_id,
          password: data.headers.project_api_key
        }
      })
    }
  }
}
