import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/actions'

export default {
  namespaced: true,
  state: {
    is_load: false,
    actions: []
  },
  mutations: {
    setActions (state, payload) {
      state.actions = payload
    },
    changeItem (state, payload) {
      const index = state.actions.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.actions[index] = payload
      }

      state.actions = [...state.actions]

      // state.actions = [
      //   ...state.actions.filter(item => item.id !== payload.id),
      //   payload
      // ]
    }
  },
  actions: {
    async getActionList ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: filters
      })
      promise.then(response => {
        state.is_load = false
        commit('setActions', response.data.data)
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async createAction ({ state, commit }, data) {
      return axios.post(baseURL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async updateAction ({ state, commit }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    }
  }
}
