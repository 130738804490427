<template>
  <v-navigation-drawer
      class="aside-gradient"
      v-model="show_aside"
      app
      dark
      clipped
      @input="setShow"
      :src="this.$vuetify.breakpoint.mobile ? '/bg.png' : '/bg.png'"
  >
    <v-list
        nav
        dense
    >
      <div v-if="this.$vuetify.breakpoint.mobile">
        <v-list-item>
          <v-list-item-content>
            <div class="d-flex align-center justify-center">
              <v-img
                  alt="Logo"
                  class="shrink mr-2 ml-2"
                  contain
                  src="/images/logo-helix-light.svg"
                  transition="scale-transition"
                  width="160"
              />
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
      <v-subheader>{{ $t('user.title') }}</v-subheader>
      <v-menu
          offset-x
          transition="scale-transition" class="mx-4"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-2" dark>
              mdi-account-circle
            </v-icon>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
              to="/dashboard/profile"
              dense
          >
            <v-list-item-title>{{ $t('user.profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
              dense
              @click="logout()">
            <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>
      <v-subheader>Menu</v-subheader>
      <v-list-item-group
          active-class="gray--text"
      >
        <v-list-item to="/dashboard/clients">
          <v-icon class="mr-2">group</v-icon>
          <v-list-item-title>Clients</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/chats">
          <v-icon class="mr-2">chat</v-icon>
          <v-list-item-title>Chats</v-list-item-title>
        </v-list-item>
        <v-divider/>
        <v-list-group
          color="gray--text"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">money</v-icon>
            <v-list-item-content>
              <v-list-item-title>Finance</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-left: 24px" to="/dashboard/finances/payouts">
            <v-icon class="mr-2">mdi-cash-clock</v-icon>
            <v-list-item-title>Payout Requests</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/finances/write-off">
            <v-icon class="mr-2">mdi-cash-clock</v-icon>
            <v-list-item-title>Write-off, Accrual</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          color="gray--text"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">settings</v-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/users">
            <v-icon class="mr-2">group</v-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/pay-systems">
            <v-icon class="mr-2">payments</v-icon>
            <v-list-item-title>Pay Systems</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/commission-presets">
            <v-icon class="mr-2">bar_chart</v-icon>
            <v-list-item-title>Commission Presets</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/limits">
            <v-icon class="mr-2">data_thresholding</v-icon>
            <v-list-item-title>Limit Presets</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/payment-gateways">
            <v-icon class="mr-2">request_quote</v-icon>
            <v-list-item-title>Payment Gateway</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/payout-systems">
            <v-icon class="mr-2">account_balance_wallet</v-icon>
            <v-list-item-title>Payout Methods</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/ssh-connections">
            <v-icon class="mr-2">dns</v-icon>
            <v-list-item-title>SSH Connections</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/online-proxy">
            <v-icon class="mr-2">mdi-server-network</v-icon>
            <v-list-item-title>Online Proxy</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/card-settings">
            <v-icon class="mr-2">credit_card</v-icon>
            <v-list-item-title>Card Settings</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/support-departments">
            <v-icon class="mr-2">forum</v-icon>
            <v-list-item-title>Support departments</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          color="gray--text"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">folder</v-icon>
            <v-list-item-content>
              <v-list-item-title>Documents</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/tech-docs">
            <v-icon class="mr-2">description</v-icon>
            <v-list-item-title>Technical Documentation</v-list-item-title>
          </v-list-item>
          <v-list-item style="padding-left: 24px" to="/dashboard/settings/juristic-docs">
            <v-icon class="mr-2">description</v-icon>
            <v-list-item-title>Juristic Documentation</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
      <v-list-item to="/dashboard/statistics/transactions">
        <v-icon class="mr-2">mdi-google-analytics</v-icon>
        <v-list-item-title>Statistics</v-list-item-title>
      </v-list-item>
      <v-list-item to="/dashboard/monitoring/transactions">
        <v-icon class="mr-2">mdi-monitor-dashboard</v-icon>
        <v-list-item-title>Monitoring</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapState } from 'vuex'
import { price } from '@/mixins/price'

export default {
  name: 'MainMenuAsideComponent',
  props: ['is_show'],
  mixins: [ws, price],
  data: () => ({
    show_aside: false,
    show_join_telegram: false
  }),
  computed: {
    ...mapState('auth', ['user'])
  },
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    // ...mapActions('content', ['getAsideArticles']),
    setShow (v) {
      this.$emit('show-main-menu', v)
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">

</style>
