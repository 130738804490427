import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/config'

export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    setConfig (state, payload) {
      state.config = payload
    }
  },
  actions: {
    async getConfig ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL)

      promise.then(response => {
        commit('setConfig', response.data)
      })

      return promise
    }
  }
}
