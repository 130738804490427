export const price = {
  methods: {
    formatPrice (amount) {
      if (!amount) {
        amount = 0
      }

      return amount.toLocaleString('en-EN', {
        style: 'currency',
        currency: 'USD'
      })

      // return c.replace('грн', '').replace('₴', '')
    },
    subPercent (amount, percent) {
      return this.formatPrice(amount * ((100 - percent) / 100))
    }
  }
}
