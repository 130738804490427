import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    operations: []
  },
  mutations: {
  },
  actions: {
    async getTransactionsStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/transactions', {
        params: query
      })
    },
    async getClientsStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/clients', {
        params: query
      })
    },
    async getPaySystemStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/pay-systems', {
        params: query
      })
    },
    async getCountiesStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/countries', {
        params: query
      })
    },
    async getBinStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/bin', {
        params: query
      })
    },
    async getSystemStatistics ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/statistics/systems', {
        params: query
      })
    }
  }
}
