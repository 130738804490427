import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/activities'

export default {
  namespaced: true,
  state: {
    is_load: false,
    activities: [],
    filters: {
      user_id: null
    },
    pagination: {
      current_page: 1,
      per_page: 10
    }
  },
  mutations: {
    setActivities (state, payload) {
      state.activities = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
    }
  },
  actions: {
    async getActivities ({ state, commit }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          project_id: filters.project_id || null,
          transaction_id: filters.transaction_id || null,
          group: filters.group || null,
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10,
          sort: filters.sort || 'asc'
        }
      })
      promise.then(response => {
        commit('setActivities', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getLogs ({ state, commit }, filters) {
      return axios.get(baseURL, { params: filters })
    }
  }
}
