<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_main_menu = !show_main_menu">
<!--        <v-badge-->
<!--          :content="count_unread"-->
<!--          :value="count_unread"-->
<!--          dot-->
<!--          left-->
<!--          color="red"-->
<!--        >-->
          <v-icon>menu</v-icon>
<!--        </v-badge>-->
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_main_menu" @show-main-menu="setShowMenu"/>
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
// import { mapActions, mapMutations, mapState } from 'vuex'
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DashboardMobileLayout',
  mixins: [translate, price, ws],
  components: {
    MainMenuAsideComponent
  },
  data: () => ({
    show_main_menu: false
    // show_aside: false,
    // searchTimer: null,
    // searchLoading: false,
    // showSearchResults: false
  }),
  computed: {
    ...mapState('auth', ['user'])
    // ...mapState('carts', ['cart']),
    // ...mapState('search', [
    //   'search_request',
    //   'autocomplete',
    //   'search_products',
    //   'search_categories',
    //   'search_history'
    // ]),
  },
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    // ...mapActions('carts', ['getCart']),
    // ...mapActions('search', ['searchRequest']),
    // ...mapMutations('search', [
    //   'setSearchRequest',
    //   'setAutocomplete',
    //   'setSearchProducts',
    //   'addSearchHistory'
    // ]),
    // ...mapMutations('products', ['setProductSearch', 'setCategoryId', 'setSorting']),
    // ...mapActions('products', ['getProducts']),
    setShowMenu (v) {
      this.show_main_menu = v
    }
  },
  created () {
    const promise = this.getUser()
    promise.then(response => {
      // this.joinChannelNotifications()
    })
    // const promise = this.getUser()
    // promise.then(response => {
    //   this.joinChannelNotifications()
    // })
    // this.setSearchRequest(this.$route.query.search)
    // this.getCart()
  }
}
</script>

<style scoped>

</style>
