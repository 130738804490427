import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/limits'

export default {
  namespaced: true,
  state: {
    is_load: false,
    limits: [],
    limit: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setLimits (state, payload) {
      state.limits = payload
    },
    setLimit (state, payload) {
      state.limit = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    }
  },
  actions: {
    async getLimits ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setLimits', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getLimitList ({
      state,
      commit
    }, filters) {
      return axios.get(baseURL, {
        params: {
          page: 1,
          per_page: filters.per_page || 100,
          type: filters.type
        }
      })
    },
    async getBaseStructure ({ state, commit }, type) {
      return axios.get(baseURL + '/get-structure', {
        params: { type: type }
      })
    },
    async getLimit ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setLimit', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createLimit ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/', data)

      promise.then(response => {
        commit('setLimit', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async updateLimit ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)

      promise.then(response => {
        commit('setLimit', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async deleteLimit ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.delete(baseURL + '/' + id)

      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
