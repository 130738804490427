import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/documents'

export default {
  namespaced: true,
  state: {
    is_load: false,
    document: null,
    documents: [],
    filters: {
      user_id: null
    },
    pagination: {
      current_page: 1
    }
  },
  mutations: {
    setDocument (state, payload) {
      state.document = payload
    },
    setDocuments (state, payload) {
      state.documents = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setUserId (state, payload) {
      state.filters.user_id = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getDocuments ({ state, commit }, projectId) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          project_id: projectId,
          page: state.pagination.current_page || 1
        }
      })
      promise.then(response => {
        commit('setDocuments', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getDocument ({ state, commit }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setDocument', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async createDocument ({ state, commit }, data) {
      return axios.post(baseURL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async deleteDocument ({ state, commit }, id) {
      return axios.delete(baseURL + '/' + id)
    },
    async downloadDocument ({ state, commit }, doc) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + doc.id, { responseType: 'blob' })
      promise.then(response => {
        state.is_load = false
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = doc.original_name + '.' + doc.extension
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
