<template>
  <v-container fluid class="set-font">
    <v-layout class="mt-16" align-center justify-center>
      <v-card elevation="12" width="360"
              :loading="is_load"
      >
        <v-toolbar
            dark
            flat
        >
          <v-toolbar-title style="margin: auto">
            <b>{{ $t('login.title') }}</b>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
              class="mt-2"
              ref="form"
              lazy-validation
          >
            <v-text-field
                autofocus
                outlined
                v-model="email"
                :label="$t('login.email')"
                name="email"
                type="email"
                autocomplete="username-1"
                :error="hasError('email')"
                :error-messages="getError('email')"
                @keyup.enter="sendLogin"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="password"
                :label="$t('login.password')"
                name="password"
                :type="password_field_type ? 'password' : 'text'"
                :error="hasError('password')"
                :error-messages="getError('password')"
                :append-icon="password_field_type ? 'visibility' : 'visibility_off'"
                @click:append="password_field_type = !password_field_type"
                @keyup.enter="sendLogin"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-btn
              :loading="is_load"
              :disabled="is_load"
              color="primary"
              @click="sendLogin()"
              large
              width="100%"
          >
            {{ $t('login.sign_in') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { translate } from '@/mixins/translate'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginPage',
  mixins: [translate],
  data () {
    return {
      email: null,
      password: null,
      password_field_type: true
    }
  },
  computed: {
    ...mapGetters('errors', ['hasError', 'getError']),
    ...mapState('auth', ['user', 'is_load'])
  },
  methods: {
    ...mapActions('auth', ['loginRequest']),
    sendLogin () {
      const promise = this.loginRequest({
        email: this.email,
        password: this.password
      })

      promise.then(r => {
        this.$router.push('/confirmation')
      })
    }
  }
}
</script>

<style scoped>

</style>
