import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/products'

export default {
  namespaced: true,
  state: {
    is_load: false,
    products: [],
    pagination: {
      order_by: 'id',
      order_direction: 'desc',
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10,
      last_page: 0
    }
  },
  mutations: {
    setProducts (state, payload) {
      state.products = payload
    },
    setPagination (state, payload) {
      state.pagination.current_page = payload.current_page
      state.pagination.per_page = payload.per_page
      state.pagination.total = payload.total
      state.pagination.last_page = payload.last_page
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setSort (state, v) {
      state.pagination.order_direction = v.order_direction
      state.pagination.order_by = v.order_by
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getProducts ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          order_direction: state.pagination.order_direction || 'desc',
          order_by: state.pagination.order_by || 'id',
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setProducts', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async import ({ state, commit }, data) {
      return axios.post(baseURL + '/import', data, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async bulkDelete ({ state, commit }, ids) {
      return axios.delete(baseURL, { data: { ids: ids } })
    }
  }
}
