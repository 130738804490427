export default {
  namespaced: true,
  state: {
    favoriteList: []
  },
  getters: {
    isFavorite: state => id => {
      return state.favoriteList.findIndex(item => item.id === id) !== -1
    }
  },
  mutations: {
    loadFavorites (state) {
      let items = localStorage.getItem('favorites')
      if (!items) {
        items = []
      } else {
        items = JSON.parse(items)
        state.favoriteList = items
      }
    },
    addFavorite (state, payload) {
      const index = state.favoriteList.findIndex(item => item.id === payload.id)
      if (index === -1) {
        state.favoriteList.push(payload)
      }
      localStorage.setItem('favorites', JSON.stringify(state.favoriteList))
    },
    deleteFavorite (state, id) {
      const index = state.favoriteList.findIndex(item => item.id === id)
      if (index !== -1) {
        delete state.favoriteList.splice(index, 1)
      }
      localStorage.setItem('favorites', JSON.stringify(state.favoriteList))
    }
  }
}
