import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    operations: []
  },
  mutations: {
  },
  actions: {
    async getOperations ({ state, commit }, query) {
      return axios.get(baseURL + '/api/admin/operations', {
        params: {
          page: query.page || 1,
          per_page: query.per_page || 10,
          project_id: query.project_id,
          transaction_id: query.transaction_id,
          dates: query.dates || null,
          type_id: query.type_id
        }
      })
    },
    async changeOperationPayableDate ({ state, commit }, data) {
      return axios.post(baseURL + '/api/admin/operations/' + data.id, data)
    }
  }
}
