import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/chats'
const messageURL = process.env.VUE_APP_SERVER_URL + '/api/admin/chat-messages'

export default {
  namespaced: true,
  state: {
    chat_id: null,
    project_id: null,
    show_chat: false,
    chat_user: null,
    is_load: false,
    chats: [],
    chat: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setChats (state, payload) {
      state.chats = payload
      state.chats.forEach(item => {
        item.label = item.user ? item.user.name : 'no-name'

        if (item.chat_branch) {
          item.label += ' | ' + item.chat_branch.name
        }

        if (item.project) {
          item.label += ' | ' + item.project.name
        }
      })
    },
    setChat (state, payload) {
      state.chat = payload
    },
    setChatId (state, id) {
      state.chat_id = id
    },
    setProjectId (state, id) {
      state.project_id = id
    },
    setChatUser (state, payload) {
      state.chat_user = payload
    },
    setShowChat (state, payload) {
      state.show_chat = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    replaceChat (state, payload) {
      const index = state.chats.findIndex(item => item.id === payload.id)
      if (index !== -1) {
        state.chats[index] = payload
      }
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setReadMessages (state, id) {
      const index = state.chats.findIndex(item => item.id === id)
      if (index !== -1) {
        state.chats[index].chat_messages_count = 0
      }
    }
  },
  actions: {
    async getChats ({
      state,
      commit
    }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: filters
      })

      promise.then(response => {
        commit('setChats', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async getChat ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setChat', response.data.data)
        // commit('replaceChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createChat ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL, data)

      promise.then(response => {
        commit('setChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async sendFile ({ state, commit }, formData) {
      return axios.post(messageURL + '/files', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async sendChatMessage ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(messageURL, data)

      promise.then(response => {
        state.is_load = false
      })

      return promise
    },
    async updateChat ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)

      promise.then(response => {
        commit('setChat', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async deleteChat ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.delete(baseURL + '/' + id)

      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async sendUpdateChatMessage ({ state, commit }, data) {
      return axios.put(messageURL + '/' + data.id, data)
    },
    async deleteMessage ({ state, commit }, id) {
      return axios.delete(messageURL + '/' + id)
    }
  }
}
