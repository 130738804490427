import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/project-currencies'

export default {
  namespaced: true,
  state: {
    is_load: false
  },
  mutations: {
  },
  actions: {
    async getRate ({
      state,
      commit
    }, currencyCode) {
      return axios.get(baseURL + '/get-rate/' + currencyCode)
    }
  }
}
