import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/online-proxies'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getOnlineProxyList ({
      state,
      commit
    }, filters) {
      state.is_load = true
      return axios.get(baseURL, {
        params: {
          page: filters.page || 1,
          per_page: filters.per_page || 10,
          search: filters.search || null,
          provider: filters.provider || null
        }
      })
    },
    async createOnlineProxy ({ state, commit }, data) {
      return axios.post(baseURL, data)
    },
    async updateOnlineProxy ({ state, commit }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    },
    async deleteOnlineProxy ({ state, commit }, id) {
      return axios.delete(baseURL + '/' + id)
    },
    async bulkDeleteOnlineProxy ({ state, commit }, ids) {
      return axios.delete(baseURL, { data: { ids: ids } })
    }
  }
}
