import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/card-settings'

export default {
  namespaced: true,
  state: {
    is_load: false,
    card_settings: {}
  },
  mutations: {
    setCardSettings (state, payload) {
      state.card_settings = payload
    }
  },
  actions: {
    async getCardSettings ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL)

      promise.then(response => {
        commit('setCardSettings', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async saveCardSetting ({
      state,
      commit
    }, data) {
      const promise = axios.post(baseURL + '/', data)
      promise.then(response => {
        commit('setCardSettings', response.data.data)
      })
      return promise
    }
  }
}
