import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/payout-systems'

export default {
  namespaced: true,
  state: {
    is_load: false,
    payout_systems: [],
    payout_system: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setPayoutSystems (state, payload) {
      state.payout_systems = payload
    },
    setPayoutSystem (state, payload) {
      state.payout_system = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getPayoutSystems ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setPayoutSystems', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getPayoutSystemsList ({
      state,
      commit
    }) {
      return axios.get(baseURL, {
        params: {
          page: 1,
          per_page: 200
        }
      })
    },

    async getPayoutSystemsMiniList ({ state, commit }, params) {
      return axios.get(baseURL + '/search', {
        params: params
      })
    },
    async getPayoutSystem ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setPayoutSystem', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createPayoutSystem ({
      state,
      commit
    }, data) {
      const promise = axios.post(baseURL + '/', data)

      return promise
    },
    async updatePayoutSystem ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)

      return promise
    },
    async deletePayoutSystem ({
      state,
      commit
    }, id) {
      const promise = axios.delete(baseURL + '/' + id)

      return promise
    }
  }
}
