<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
      src="/bgapp.svg"
    >
      <div
        @click="$router.push('/dashboard/projects').catch(e => {})"
      >
        <v-img
          alt="Logo"
          class="shrink mr-1 ml-1"
          contain
          src="/logo-admin.svg"
          transition="scale-transition"
          width="240"
        />
      </div>
      <v-spacer></v-spacer>
      <!--  User    -->
      <v-toolbar-items>
        <v-menu
          v-if="favoriteList && favoriteList.length > 0"
          offset-y :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
            >
              <v-icon>mdi-star</v-icon>
            </v-btn>
          </template>
          <v-card width="400px">
            <v-card-title>Favorites</v-card-title>
            <v-divider/>
            <v-list dense>
              <v-list-item
                v-for="item in favoriteList" :key="item.id"
                @click="goToFavorite(item)"
              >
                <v-list-item-avatar>
                  <v-icon
                    class="grey lighten-1"
                    dark
                  > {{ item.type === 'project' ? 'list' : 'person' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop="deleteFavorite(item.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
            >
              <v-icon>person</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  class="grey lighten-1"
                  dark
                >person
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 16px">
                  {{ user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group
              color="primary"
            >
              <v-list-item @click="$router.push('/dashboard/profile')">
                <v-list-item-title>
                  {{ $t('user.profile') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title>{{ $t('login.out') }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          text
          @click="show_settings = !show_settings"
        >
          <v-icon>settings</v-icon>
        </v-btn>
        <NotificationsComponent/>
      </v-toolbar-items>
      <!--    Notifications-->
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_aside"/>
    <SettingAsidePanel v-model="show_settings"/>
    <!--    <ChatAsideComponent/>-->
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import { translate } from '@/mixins/translate'
import { price } from '@/mixins/price'
import { ws } from '@/mixins/ws_notification'
import { mapActions, mapMutations, mapState } from 'vuex'
import SettingAsidePanel from '@/components/asides/SettingAsidePanel.vue'
import NotificationsComponent from '@/components/Notifications/NotificationsComponent.vue'

export default {
  name: 'DashboardLayout',
  mixins: [translate, price, ws],
  components: {
    NotificationsComponent,
    SettingAsidePanel,
    MainMenuAsideComponent
  },
  data: () => ({
    menu: true,
    show_settings: false,
    show_aside: true,
    show_action_aside: false,
    show_notifications: false,
    selected: null
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('favorites', ['favoriteList']),
    selectedLang: {
      get () {
        return this.lang
      },
      set (v) {
        // this.setLang(this.lang_list[v])
        // const lang = v === 0 ? 'uk' : 'ru'
        // this.changeUserLang(lang)
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions('auth', ['getUser', 'logout']),
    ...mapActions('config', ['getConfig']),
    ...mapMutations('favorites', ['loadFavorites', 'deleteFavorite']),
    toNotifications () {
      if (this.$route.path === '/dashboard/notifications') {
        // this.setNotificationPage(1)
        // this.getNotifications()
      } else {
        this.$router.push('/dashboard/notifications')
      }
    },
    goToFavorite (item) {
      const url = '/dashboard/' + (item.type === 'project' ? 'projects/' : 'clients/') + item.id + (item.type === 'project' ? '/general' : '')
      this.$router.push(url)
    }
  },
  created () {
    this.loadFavorites()
    this.getConfig()
    const promise = this.getUser()
    promise.then(response => {
      this.joinWS()
    })
  }
}
</script>

<style lang="scss">
</style>
