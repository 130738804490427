import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/site-payments'

export default {
  namespaced: true,
  state: {
    is_load: false,
    site_payments: [],
    site_payment: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setSitePayments (state, payload) {
      state.site_payments = payload
    },
    setSitePayment (state, payload) {
      state.site_payment = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getSitePayments ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setSitePayments', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getSitePaymentFullList ({
      state,
      commit
    }, type) {
      return axios.get(baseURL, {
        params: {
          type: type,
          page: 1,
          per_page: 200
        }
      })
    },

    async getSitePayment ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setSitePayment', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createSitePayment ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/', data)
      promise.then(r => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })
      return promise
    },
    async updateSitePayment ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)

      return promise
    },
    async getRate ({
      state,
      commit
    }, currency) {
      return axios.get(baseURL + '/rate/' + currency)
    }
  }
}
