import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/corrections'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async createCorrection ({
      state,
      commit
    }, data) {
      return axios.post(baseURL, data)
    }
  }
}
