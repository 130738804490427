import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/views/Auth/LoginPage.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'LSP - '

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: { layout: 'base-layout', title: 'Login' }
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('../views/Auth/CodeConfirmPage'),
    meta: { layout: 'base-layout', title: 'Confirm Login' }
  },
  {
    path: '/dashboard/clients',
    name: 'client-list',
    component: () => import('../views/Clients/ClientListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Clients' }
  },
  {
    path: '/dashboard/clients/:id',
    name: 'client-view',
    component: () => import('../views/Clients/ClientPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Client Page' }
  },
  // Project
  {
    path: '/dashboard/projects/:id',
    name: 'project-view',
    component: () => import('../views/Projects/ProjectPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Project' },
    children: [
      {
        path: 'general',
        name: 'project-general',
        component: () => import('../views/Projects/Components/GeneralView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Project General Info' }
      },
      {
        path: 'documents',
        name: 'project-documents',
        component: () => import('../views/Projects/Components/DocumentsView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Documents' }
      },
      {
        path: 'commissions',
        name: 'project-commissions',
        component: () => import('../views/Projects/Components/CommissionView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Commission' }
      },
      {
        path: 'limits',
        name: 'project-limits',
        component: () => import('../views/Projects/Components/LimitsView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Limits' }
      },
      {
        path: 'payout-methods',
        name: 'project-payout-methods',
        component: () => import('../views/Projects/Components/PayoutMethodsView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Payout methods' }
      },
      {
        path: 'payment-methods',
        name: 'project-payment-methods',
        component: () => import('../views/Projects/Components/PaymentMethodsView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Payment methods' }
      },
      {
        path: 'currencies',
        name: 'project-currencies',
        component: () => import('../views/Projects/Components/CurrenciesView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Currencies' }
      },
      {
        path: 'balance',
        name: 'project-balance',
        component: () => import('../views/Projects/Components/BalanceView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Balance' }
      },
      {
        path: 'activities',
        name: 'project-activities',
        component: () => import('../views/Projects/Components/ActivityView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Activity' }
      },
      {
        path: 'transactions',
        name: 'project-transactions',
        component: () => import('../views/Projects/Transactions/TransactionListView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Transactions' }
      },
      {
        path: 'transactions/:transaction_id',
        name: 'project-transaction-show',
        component: () => import('../views/Projects/Transactions/TransactionView.vue'),
        meta: { layout: 'dashboard-layout', title: 'Transaction' }
      }
    ]
  },
  // Chats
  {
    path: '/dashboard/chats',
    name: 'ChatPage',
    component: () => import('../views/Chats/ChatPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Chat' }
  },
  // Finances
  {
    path: '/dashboard/finances/payouts',
    name: 'payout-list-view',
    component: () => import('../views/Finances/PayoutListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Payouts' }
  },
  {
    path: '/dashboard/finances/write-off',
    name: 'write-off-view',
    component: () => import('../views/Finances/WriteOffListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Write OFF' }
  },
  // Settings
  // Support departments
  {
    path: '/dashboard/settings/support-departments',
    name: 'SupportDepartmentsPage',
    component: () => import('../views/Settings/SupportDepartments/SupportDepartmentsPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Departments' }
  },
  // Limits
  {
    path: '/dashboard/settings/limits',
    name: 'limit-list-page',
    component: () => import('../views/Settings/Limits/LimitListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Limits' }
  },
  {
    path: '/dashboard/settings/limits/create',
    name: 'create-limit-page',
    component: () => import('../views/Settings/Limits/CreateLimitPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Limit creation' }
  },
  {
    path: '/dashboard/settings/limits/:id/edit',
    name: 'edit-limit-page',
    component: () => import('../views/Settings/Limits/EditLimitPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Creating a limit' }
  },
  // Commission Presets
  {
    path: '/dashboard/settings/commission-presets',
    name: 'commission-presets-list-page',
    component: () => import('../views/Settings/CommissionPresets/CommissionPresetListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Editing the limit' }
  },
  {
    path: '/dashboard/settings/commission-presets/create',
    name: 'commission-presets-create-page',
    component: () => import('../views/Settings/CommissionPresets/CreateCommissionPresetPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Preset Commission' }
  },
  {
    path: '/dashboard/settings/commission-presets/:id/edit',
    name: 'commission-presets-edit-page',
    component: () => import('../views/Settings/CommissionPresets/EditCommissionPresetPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Editing a preset' }
  },
  // Payment Gateways
  {
    path: '/dashboard/settings/payment-gateways',
    name: 'payment-gateways-list-page',
    component: () => import('../views/Settings/PaymentGateways/PaymentGatewayListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Payment Gateways' }
  },
  {
    path: '/dashboard/settings/payment-gateways/create',
    name: 'payment-gateways-create-page',
    component: () => import('../views/Settings/PaymentGateways/CreatePaymentGatewayPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Creating a Gateway' }
  },
  {
    path: '/dashboard/settings/payment-gateways/:id/edit',
    name: 'payment-gateways-edit-page',
    component: () => import('../views/Settings/PaymentGateways/EditPaymentGatewayPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Editing a Gateway' }
  },
  // Payout Methods
  {
    path: '/dashboard/settings/payout-systems',
    name: 'payout-systems-list-page',
    component: () => import('../views/Settings/PayoutSystems/PayoutSystemListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Payout methods' }
  },
  {
    path: '/dashboard/settings/payout-systems/create',
    name: 'payout-systems-create-page',
    component: () => import('../views/Settings/PayoutSystems/CreatePayoutSystemPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Creating a Payout method' }
  },
  {
    path: '/dashboard/settings/payout-systems/:id/edit',
    name: 'payout-systems-edit-page',
    component: () => import('../views/Settings/PayoutSystems/EditPayoutSystemPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Editing a Payout method' }
  },
  // Pay Systems (site payment)
  {
    path: '/dashboard/settings/pay-systems',
    name: 'pay-systems-page',
    component: () => import('../views/Settings/SitePayments/SitePaymentListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Pay methods' }
  },
  {
    path: '/dashboard/settings/pay-systems/create',
    name: 'pay-systems-create-page',
    component: () => import('../views/Settings/SitePayments/CreateSitePaymentPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Creating a Pay method' }
  },
  {
    path: '/dashboard/settings/pay-systems/:id/edit',
    name: 'pay-systems-edit-page',
    component: () => import('../views/Settings/SitePayments/EditSitePaymentPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Editing a Pay method' }
  },
  {
    path: '/dashboard/settings/ssh-connections',
    name: 'ssh-connection-list-page',
    component: () => import('../views/Settings/SshConnections/SshConnectionListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Ssh' }
  },
  {
    path: '/dashboard/settings/online-proxy',
    name: 'online-proxy-list-page',
    component: () => import('../views/Settings/SshConnections/OnlineProxyListPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Online Proxy' }
  },
  {
    path: '/dashboard/settings/card-settings',
    name: 'card-settings-page',
    component: () => import('../views/Settings/CardSettings/CardSettingsPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Card Settings' }
  },
  {
    path: '/dashboard/settings/tech-docs',
    name: 'tech-docs-list-page',
    component: () => import('../views/Settings/TechnicalDocumentation/TechDocsPage.vue'),
    meta: { layout: 'dashboard-layout', title: 'Tech Docs' }
  },
  {
    path: '/dashboard/settings/juristic-docs',
    name: 'juristic-docs-list-page',
    component: () => import('../views/Settings/JuristicDocumentation/JuristicDocsPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/settings/users',
    name: 'users-list-page',
    component: () => import('../views/Settings/Users/UserListPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/settings/users/create',
    name: 'users-create-page',
    component: () => import('../views/Settings/Users/CreateUserPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/settings/users/:id/edit',
    name: 'users-edit-page',
    component: () => import('../views/Settings/Users/UpdateUserPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/notifications',
    name: 'notifications-page',
    component: () => import('../views/Notifications/NotificationListPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/config/system',
    name: 'conf-page',
    component: () => import('../views/Config/SystemConfigPage.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  {
    path: '/dashboard/config/articles',
    name: 'conf-page',
    component: () => import('../views/Config/ArticlesView.vue'),
    meta: { layout: 'dashboard-layout' }
  },
  // Statistics
  {
    path: '/dashboard/statistics',
    component: () => import('../views/Statistics/StatisticPage.vue'),
    meta: { layout: 'dashboard-layout' },
    children: [
      {
        path: 'transactions',
        component: () => import('../views/Statistics/Pages/TransactionsStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Transactions Statistics' }
      },
      {
        path: 'clients',
        component: () => import('../views/Statistics/Pages/ClientsStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Clients Statistics' }
      },
      {
        path: 'pay-systems',
        component: () => import('../views/Statistics/Pages/PaySystemStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'PS Statistics' }
      },
      {
        path: 'countries',
        component: () => import('../views/Statistics/Pages/CountriesStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Countries Statistics' }
      },
      {
        path: 'bin',
        component: () => import('../views/Statistics/Pages/BinStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'BINs Statistics' }
      },
      {
        path: 'system',
        component: () => import('../views/Statistics/Pages/SystemStatisticPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'System' }
      }
    ]
  },
  // Monitoring
  {
    path: '/dashboard/monitoring',
    component: () => import('../views/Monitoring/MonitoringPage.vue'),
    meta: { layout: 'dashboard-layout' },
    children: [
      {
        path: 'transactions',
        component: () => import('../views/Monitoring/Pages/TransactionsPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Transactions' }
      },
      {
        path: 'violations',
        component: () => import('../views/Monitoring/Pages/ViolationsPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Violations' }
      },
      {
        path: 'customer-actions',
        component: () => import('../views/Monitoring/Pages/CustomerActionsPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Customer Actions' }
      },
      {
        path: 'admin-actions',
        component: () => import('../views/Monitoring/Pages/AdminActionsPage.vue'),
        meta: { layout: 'dashboard-layout', title: 'Admin Actions' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE + (to.meta.title || '')
  })
})

export default router
