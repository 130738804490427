import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import errors from '@/store/modules/errors'
import lang from '@/store/modules/lang'
import clients from '@/store/modules/clients'
import projects from '@/store/modules/projects'
import chatBranches from '@/store/modules/chatBranches'
import users from '@/store/modules/users'
import chat from '@/store/modules/chat'
import limits from '@/store/modules/limits'
import limitPresets from '@/store/modules/limitPresets'
import actions from '@/store/modules/actions'
import documents from '@/store/modules/documents'
import sitePayments from '@/store/modules/sitePayments'
import commissionPresets from '@/store/modules/commissionPresets'
import paymentGateways from '@/store/modules/paymentGateways'
import payoutSystems from '@/store/modules/payoutSystems'
import projectCurrencies from '@/store/modules/projectCurrencies'
import sshConnections from '@/store/modules/sshConnections'
import cardSettings from '@/store/modules/cardSettings'
import systemDocuments from '@/store/modules/systemDocuments'
import products from '@/store/modules/products'
import activities from '@/store/modules/activities'
import transactions from '@/store/modules/transactions'
import payouts from '@/store/modules/payouts'
import corrections from '@/store/modules/corrections'
import notifications from '@/store/modules/notifications'
import systemSettings from '@/store/modules/systemSettings'
import operations from '@/store/modules/operations'
import articles from '@/store/modules/articles'
import onlineProxies from '@/store/modules/onlineProxies'
import statistics from '@/store/modules/statistics'
import favorites from '@/store/modules/favorites'
import violations from '@/store/modules/violations'
import config from '@/store/modules/config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    errors,
    lang,
    clients,
    projects,
    chatBranches,
    users,
    chat,
    limits,
    limitPresets,
    actions,
    documents,
    sitePayments,
    commissionPresets,
    paymentGateways,
    payoutSystems,
    projectCurrencies,
    sshConnections,
    cardSettings,
    systemDocuments,
    products,
    activities,
    transactions,
    payouts,
    corrections,
    notifications,
    systemSettings,
    operations,
    articles,
    onlineProxies,
    statistics,
    favorites,
    violations,
    config
  }
})
