import axios from 'axios'
import store from '../store'
import router from '@/router'

const RequestErrors = {
  connect () {
    axios.interceptors.response.use((response) => {
      store.commit('errors/clearErrors')
      return response
    }, function (error) {
      if (error.response.status === 401) {
        if (router.currentRoute.fullPath !== '/') {
          router.push('/')
        }
      }
      if (error && error.response.status === 422) {
        store.commit('errors/setErrors', error.response.data)
      }

      if (error && error.response.status === 500) {
        store.commit('errors/setErrors', {
          message: 'Server Error 500!'
        })
      }

      if (error && error.response.status === 403) {
        store.commit('errors/setErrors', {
          message: 'Access to this resource is closed for you.'
        })
      }

      return Promise.reject(error)
    })
  }
}

export default RequestErrors
