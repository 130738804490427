import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/limit-presets'

export default {
  namespaced: true,
  state: {
    is_load: false,
    limit_presets: [],
    limit_preset: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setLimitPresets (state, payload) {
      state.limit_presets = payload
    },
    setLimitPreset (state, payload) {
      state.limit_preset = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getLimitPresets ({
      state,
      commit
    }, search) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          name: search
        }
      })

      promise.then(response => {
        commit('setLimitPresets', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async createLimitPreset ({
      state,
      commit
    }, data) {
      const promise = axios.post(baseURL + '/', data)

      return promise
    },
    async updateLimitPreset ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)

      return promise
    },
    async deleteLimitPreset ({
      state,
      commit
    }, id) {
      const promise = axios.delete(baseURL + '/' + id)

      return promise
    }
  }
}
