<template>
  <v-navigation-drawer
    app
    v-model="show"
    right
    clipped
    dark
  >
    <v-list
      nav
      dense
    >
      <v-list-item-group
        active-class="gray--text"
      >
        <v-list-item @click="show = false" to="/dashboard/config/system">
          <v-icon class="mr-2">settings</v-icon>
          <v-list-item-title>System Configuration</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/config/articles">
          <v-icon class="mr-2">help</v-icon>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'SettingAsidePanel',
  props: ['value'],
  computed: {
    show: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style scoped>

</style>
