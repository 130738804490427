import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
    is_load: false,
    user: null,
    access_token: `Bearer ${localStorage.getItem('access_token') || ''}`,
    access: `Bearer ${localStorage.getItem('guard_access') || ''}`
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setAccessToken (state, token) {
      state.access_token = token
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      localStorage.setItem('access_token', token)
    },
    setAccess (state, access) {
      state.access = access
      localStorage.setItem('guard_access', access)
    }
  },
  actions: {
    async loginRequest ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/admin/auth/login', data)

      promise.then(response => {
        commit('setAccess', response.data.access)
        state.is_load = false
      }).catch(r => {
        state.is_load = false
      })

      return promise
    },
    async confirmRequest ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/api/admin/auth/confirm', data)

      promise.then(response => {
        commit('setAccessToken', response.data.access_token)
        commit('setUser', response.data.data)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    },
    async getUser ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL + '/api/admin/auth')

      promise.then(response => {
        commit('setUser', response.data.user)
        state.is_load = false
        // commit('lang/setLang', response.data.data.lang, { root: true })
      }).catch(error => {
        state.is_load = false
        if (error.response.status === 401 && window.location.pathname !== '/') {
          localStorage.setItem('access_token', null)
          window.location = '/'
        }
      })

      return promise
    },
    checkAccess ({ commit }) {
      return axios.post(baseURL + '/api/auth/access', {
        access: localStorage.getItem('guard_access')
      })
    },
    updateProfile ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/api/auth/profile', data)
      promise.then(response => {
        commit('setUser', response.data.user)
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })
      return promise
    },
    logout ({ commit }) {
      const promise = axios.delete(baseURL + '/api/auth/')
      promise.then(r => {
        commit('setUser', null)
        localStorage.setItem('access_token', null)
        window.location = '/'
      })
    }
  }
}
