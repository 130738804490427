import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL

export default {
  namespaced: true,
  state: {
  },
  actions: {
    async getViolations ({ state, commit }, params) {
      return axios.get(baseURL + '/api/admin/violation-logs', { params: params })
    }
  }
}
