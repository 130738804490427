import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import Echo from 'laravel-echo'
import RequestErrors from './plugins/errorHelper'
import Notifications from 'vue-notification'
import VueMoment from 'vue-moment'
import VueSmoothScroll from 'vue2-smooth-scroll'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import BaseLayout from '@/layouts/BaseLayout'
import BaseMobileLayout from '@/layouts/mobile/BaseMobileLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import DashboardMobileLayout from '@/layouts/mobile/DashboardMobileLayout'

i18n.locale = localStorage.getItem('system-locale') || 'en'
axios.defaults.headers.crossdomain = true
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token') || ''}`
axios.defaults.headers.common.localization = localStorage.getItem('system-locale') || 'en'

Vue.component('base-layout', BaseLayout)
Vue.component('base-layout-mobile', BaseMobileLayout)
Vue.component('dashboard-layout', DashboardLayout)
Vue.component('dashboard-layout-mobile', DashboardMobileLayout)

Vue.use(VueSmoothScroll)

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(VueMoment)

RequestErrors.connect()

export const eventBus = new Vue()

window.io = require('socket.io-client')

if (localStorage.getItem('access_token')) {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_SOCKET_HOST,
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`
      }
    }
  })
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
