import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/commission-presets'

export default {
  namespaced: true,
  state: {
    is_load: false,
    commission_presets: [],
    commission_preset: null,
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setCommissionPresets (state, payload) {
      state.commission_presets = payload
    },
    setCommissionPreset (state, payload) {
      state.commission_preset = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    }
  },
  actions: {
    async getCommissionPresets ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setCommissionPresets', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getCommissionPresetList ({
      state,
      commit
    }) {
      return axios.get(baseURL, {
        params: {
          page: 1,
          per_page: 200
        }
      })
    },
    async getBaseStructure ({ state, commit }) {
      return axios.get(baseURL + '/get-structure', {})
    },
    async getCommissionPreset ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + id)

      promise.then(response => {
        commit('setCommissionPreset', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createCommissionPreset ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL + '/', data)

      promise.then(response => {
        commit('setCommissionPreset', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async updateCommissionPreset ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)

      promise.then(response => {
        commit('setCommissionPreset', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async deleteCommissionPreset ({
      state,
      commit
    }, id) {
      state.is_load = true
      const promise = axios.delete(baseURL + '/' + id)

      promise.then(response => {
        state.is_load = false
      }).catch(e => {
        state.is_load = false
      })

      return promise
    }
  }
}
