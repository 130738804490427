import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/system-documents'

export default {
  namespaced: true,
  state: {
    is_load: false,
    documents: [],
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setDocuments (state, payload) {
      state.documents = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    }
  },
  actions: {
    async getSystemDocuments ({
      state,
      commit
    }, filters) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          type_id: filters.type_id || null,
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setDocuments', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async createSystemDocument ({
      state,
      commit
    }, data) {
      return axios.post(baseURL, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    async updateSystemDocument ({
      state,
      commit
    }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    },
    async deleteSystemDocument ({
      state,
      commit
    }, id) {
      return axios.delete(baseURL + '/' + id)
    }
  }
}
