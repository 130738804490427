import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/users'

export default {
  namespaced: true,
  state: {
    is_load: false,
    users: [],
    user: null,
    filters: {
      search: null
    },
    pagination: {
      current_page: 1,
      per_page: localStorage.getItem('helix_per_page') || 10
    }
  },
  mutations: {
    setUsers (state, payload) {
      state.users = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setSearch (state, payload) {
      state.filters.search = payload
    },
    setPage (state, value) {
      state.pagination.current_page = value
      if (value > state.pagination.last_page) {
        state.pagination.current_page = state.pagination.last_page
      }
      if (value < 1) {
        state.pagination.current_page = 1
      }
    },
    setPerPage (state, value) {
      state.pagination.per_page = value
      localStorage.setItem('helix_per_page', value)
    }
  },
  actions: {
    async getUsers ({
      state,
      commit
    }) {
      state.is_load = true
      const promise = axios.get(baseURL, {
        params: {
          search: state.filters.search,
          page: state.pagination.current_page || 1,
          per_page: state.pagination.per_page || 10
        }
      })

      promise.then(response => {
        commit('setUsers', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_load = false
      })

      return promise
    },
    async getUser ({
      state,
      commit
    }, userID) {
      state.is_load = true
      const promise = axios.get(baseURL + '/' + userID)

      promise.then(response => {
        commit('setUser', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async createUser ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.post(baseURL, data)

      promise.then(response => {
        commit('setUser', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async updateUser ({
      state,
      commit
    }, data) {
      state.is_load = true
      const promise = axios.put(baseURL + '/' + data.id, data)

      promise.then(response => {
        commit('setUser', response.data.data)
        state.is_load = false
      })

      return promise
    },
    async deleteUser ({
      state,
      commit
    }, id) {
      return axios.delete(baseURL + '/' + id)
    }
  }
}
