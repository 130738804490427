import axios from 'axios'

const baseURL = process.env.VUE_APP_SERVER_URL + '/api/admin/settings'

export default {
  namespaced: true,
  actions: {
    async getSystemSettings ({
      state,
      commit
    }) {
      return axios.get(baseURL)
    },
    async updateSystemSetting ({
      state,
      commit
    }, data) {
      return axios.put(baseURL + '/' + data.id, data)
    }
  }
}
